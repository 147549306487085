html, body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  height: 100%;
}

#map {
  height: 100%;
}